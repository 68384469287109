// !IMPORTANT We need to add the chain slugs every time we add a new chain as it is important to be able to create the urls of the api calls correctly
export type ChainApi = (typeof CHAIN_API_LIST)[number]; // thanks to the const assertion (as of Typescript 3.4)

export const CHAIN_API_LIST = [
  'ethereum',
  'bnb',
  'polygon',
  'fantom',
  'cronos',
  'avalanche',
  'velas',
  'oasis',
  'metis',
  'kucoin',
  'optimism',
  'arbitrum',
  'celo',
  'telos',
  'aurora',
  'moonbeam',
  'moonriver',
  'harmony',
  'astar',
  'fuse',
  'iotex',
  'oktc',
  'heco',
  'milkomeda',
  'dfk',
  'solana',
  'evmos',
  'dogechain',
  'canto',
  'etc',
  'gnosis',
  'bitgert',
  'arbitrumnova',
  'songbird',
  'redlight',
  'kardia',
  'smartbch',
  'conflux',
  'tomb',
  'boba',
  'elastos',
  'shiden',
  'fusion',
  'wan',
  'rsk',
  'nova',
  'hoo',
  'cube',
  'syscoin',
  'ronin',
  'viction',
  'kava',
  'thundercore',
  'meter',
  'echelon',
  'shib',
  'ethw',
  'kek',
  'dis',
  'sx',
  'muu',
  'alvey',
  'aptos',
  'multiversx',
  'flare',
  'pom',
  'ultron',
  'energi',
  'exosama',
  'coredao',
  'ethergoerli',
  'filecoin',
  'zksync',
  'polygonzkevm',
  'pulse',
  'klaytn',
  'linea',
  'base',
  'mantle',
  'bitrock',
  'opbnb',
  'shibarium',
  'starknet',
  'scroll',
  'manta',
  'kujira',
  'blast',
  'bittorrent',
  'osmosis',
  'xlayer',
  'shimmer',
  'mode',
  'ton',
  'hedera',
  'near',
  'tron',
  'bitlayer',
  'zklinknova',
  'apechain',
  'elysium',
  'zkfair',
  'cronoszkevm',
  'iotaevm',
  'worldchain',
] as const;

// TODO Check ports
export const CHAIN_API_AND_PORT_LIST: [chain: ChainApi, portApi: number][] = [
  ['ethereum', 3_030],
  ['bnb', 3_040],
  ['polygon', 3_050],
  ['fantom', 3_070],
  ['cronos', 3_080],
  ['avalanche', 3_090],
  ['velas', 3_100],
  ['oasis', 3_110],
  ['metis', 3_120],
  ['kucoin', 3_120],
  ['optimism', 3_120],
  ['arbitrum', 3_120],
  ['celo', 3_120],
  ['telos', 3_120],
  ['aurora', 3_120],
  ['moonbeam', 3_120],
  ['moonriver', 3_120],
  ['harmony', 3_120],
  ['astar', 3_120],
  ['fuse', 3_120],
  ['iotex', 3_120],
  ['oktc', 3_120],
  ['heco', 3_120],
  ['milkomeda', 3_120],
  ['dfk', 3_120],
  ['solana', 3_120],
  ['evmos', 3_120],
  ['dogechain', 3_120],
  ['canto', 3_120],
  ['etc', 3_120],
  ['gnosis', 3_120],
  ['bitgert', 3_120],
  ['arbitrumnova', 3_120],
  ['songbird', 3_120],
  ['redlight', 3_120],
  ['kardia', 3_120],
  ['smartbch', 3_120],
  ['conflux', 3_120],
  ['tomb', 3_120],
  ['boba', 3_120],
  ['elastos', 3_120],
  ['shiden', 3_120],
  ['fusion', 3_120],
  ['wan', 3_120],
  ['rsk', 3_120],
  ['nova', 3_120],
  ['hoo', 3_120],
  ['cube', 3_120],
  ['syscoin', 3_120],
  ['ronin', 3_120],
  ['viction', 3_120],
  ['kava', 3_120],
  ['thundercore', 3_120],
  ['meter', 3_120],
  ['echelon', 3_120],
  ['shib', 3_120],
  ['ethw', 3_120],
  ['kek', 3_120],
  ['dis', 3_120],
  ['sx', 3_120],
  ['muu', 3_120],
  ['alvey', 3_120],
  ['aptos', 3_120],
  ['multiversx', 3_120],
  ['flare', 3_120],
  ['pom', 3_120],
  ['ultron', 3_120],
  ['energi', 3_120],
  ['exosama', 3_120],
  ['coredao', 3_120],
  ['ethergoerli', 3_120],
  ['filecoin', 3_120],
  ['zksync', 3_120],
  ['polygonzkevm', 3_120],
  ['pulse', 3_120],
  ['klaytn', 3_120],
  ['linea', 3_120],
  ['base', 3_120],
  ['mantle', 3_120],
  ['bitrock', 3_120],
  ['opbnb', 3_120],
  ['shibarium', 3_120],
  ['starknet', 3_120],
  ['scroll', 3_120],
  ['manta', 3_120],
  ['kujira', 3_120],
  ['blast', 3_120],
  ['bittorrent', 3_120],
  ['osmosis', 3_120],
  ['xlayer', 3_120],
  ['shimmer', 3_120],
  ['mode', 3_120],
  ['ton', 3_120],
  ['hedera', 3_120],
  ['near', 3_120],
  ['tron', 3_120],
  ['bitlayer', 3_120],
  ['zklinknova', 3_120],
  ['apechain', 3_120],
  ['elysium', 3_120],
  ['zkfair', 3_120],
  ['cronoszkevm', 3_120],
  ['iotaevm', 3_120],
  ['worldchain', 3_120],
];
